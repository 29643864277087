const faqsData = [
  {
    title: 'What is health insurance?',
    description:
      'Health insurance is an insurance product that provides cover for medical and surgical expenses of an insured person, in case of a medical emergency. However, you are required to pay a premium to avail health insurance policy.',
  },
  {
    title: 'What is the eligible age to buy health insurance?',
    description:
      'While eligibility age for health insurance policies differs, the general eligibility age for adults ranges between 18 years up to 65 years. The eligibility age for children lies between 90 days up to 18 years.',
  },
  {
    title: 'Why should I buy health insurance?',
    description:
      'You should purchase health insurance so that you don’t lose your lifelong savings while paying for medical bills in a critical situation.',
  },
  {
    title: 'Will I be allowed to cover my family under my health insurance?',
    description:
      'Yes! You can gain coverage for self, spouse, children, dependent parents, and multiple other relationships such as parents-in-law, siblings, and others if your plan allows.',
  },
  {
    title: 'How will health insurance pay for my emergency medical expenses?',
    description:
      'Your health insurance will either pay your hospital bills directly if opted for the cashless facility or it will reimburse any payment made by you towards medical expenses incurred due to an illness or injury.',
  },
  {
    title: 'Will I be allowed to buy more than one health insurance plan?',
    description: 'Yes, you are free to buy another plan based on your specific medical needs.',
  },
  {
    title: 'Can I pay my health insurance premium in installments?',
    description:
      'In general, the health insurance premium is paid on a yearly basis. But, you can pay your premium in installments (monthly, quarterly or half-yearly basis) as well.',
  },
  {
    title: 'What are the types of health insurance plans I can buy?',
    description:
      'You can buy Individual Health Insurance Plan, Family Floater Health Insurance Plan, Senior Citizen Health Insurance Plan, Critical Illness Insurance Plan, Maternity Health Insurance Plan, Group Health Insurance Plan, Personal Accident Insurance Plan.',
  },
  {
    title: 'What are the common coverage benefits under my health insurance?',
    description:
      'Health insurance benefits differ from policy to policy. However, basic health insurance benefits include cover for an inpatient hospitalization, pre & post hospitalization, daycare procedures, emergency ambulance expenses, organ donor expenses, domiciliary hospitalization, OPD expenses, and more.',
  },
  {
    title: 'What if I miss the premium renewal date?',
    description:
      'In case of missed premium renewal due date, your insurance company will give you a grace period of 15-30 days. If you again miss making the premium payment during the grace period, you may be denied coverage, denied policy renewal, lose out on no claim bonus, or asked to serve with waiting periods from the start.',
  },
  {
    title: 'Will I be eligible for tax benefits if I buy health insurance?',
    description:
      'Yes! Buying health insurance will earn you the eligibility to claim tax benefits under Section 80D of the Income Tax Act, 1961.',
  },
  {
    title: 'What documents do I need if I want to buy health insurance?',
    description:
      'If you want to buy health insurance, you need to submit the documents like aadhaar card, voter ID, driving license, and pre-medical check-up report (in some cases). The documents are required for age proof, identity proof, address proof, and medical check-up',
  },
];

export default faqsData;
