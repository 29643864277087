import React, { useEffect } from "react";
import { Button, Row, Col, Collapse } from "antd";
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";
import { navigate } from "gatsby";
import Layout from "../../components/Layout";
import faqsData from "../../default-data/faqsData";

import "./styles/faqs-page.scss";

const SERVICE_LINE = process.env.INSURITA_SERVICE_LINE;

const { Panel } = Collapse;

const FaqsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  const renderFaqs = () => {
    return faqsData.map((faq, index) => {
      return (
        <Panel header={faq.title} key={index}>
          <p>{faq.description}</p>
        </Panel>
      );
    });
  };
  return (
    <Layout>
      <div className="faqs-page">
        <section className="wrapper-md faqs-page__hero">
          <div className="contact-page__content">
            <div>
              <h2 className="mb-3 is-size-3-mobile is-size-2-tablet has-text-weight-bold contact-page__hero__card__title line-height-2">
                FAQs
              </h2>
              <p className="line-height-3 mb-5 has-text-weight-light">
                Here are the usual questions that are asked, if ever you want to talk to us
                directly, you can reach us through here{" "}
                <a className="contact-page__content__number" href={`tel:${SERVICE_LINE}`}>
                  {SERVICE_LINE}
                </a>
              </p>
            </div>
          </div>

          <div className="has-text-centered">
            <Collapse className="faqs-page__collapse" bordered={false} defaultActiveKey={[]}>
              {renderFaqs()}
            </Collapse>
          </div>
        </section>

        <section className="section faqs-page__more">
          <div className="faqs-page__more__content wrapper-sm has-text-white">
            <h2 className="mb-4 is-size-3-mobile is-size-2-tablet has-text-weight-bold faqs-page__hero__card__title line-height-2 has-text-white">
              Haven't answered your question?
            </h2>
            <p className="line-height-3 mb-6">
              We are always ready to talk and reach out to you if you need help with setting up your
              health insuranace. Feel free to call us or send us a message!
            </p>
            <Row gutter={[24, 24]} style={{ width: "100%" }}>
              <Col xs={24} md={12}>
                <Button
                  className="button is-primary is-inverted is-outlined"
                  type="primary"
                  size="large"
                  onClick={() => navigate("/contact")}
                  block
                >
                  <MailOutlined />
                  SEND MESSAGE
                </Button>
              </Col>
              <Col xs={24} md={12}>
                <Button
                  className="button is-inverted faqs-page__more__button"
                  type="primary"
                  size="large"
                  onClick={() => window.open(`tel:${SERVICE_LINE}`, "_self")}
                  block
                >
                  <PhoneOutlined />
                  CALL {SERVICE_LINE}
                </Button>
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default FaqsPage;
